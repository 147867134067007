import { Role } from "@/acl/roles";
import { BANCA_CREDINVEST_PRODUCTS_DETAILS } from "@/assets/constants/products/banca-credinvest";

export default {
  orgName: "Banca Credinvest",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/banca-credinvest.png"),
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/banca-credinvest.jpg"),
    darkRatio: 0.5,
  },
  withProductsFilteringAbility: true,
  withProductsFilteringAbilityOnHome: true,
  numberOfProductsToShow: 6,
  numberOfProductsToShowOnHome: 6,
  products: BANCA_CREDINVEST_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "de"],
  followFeatureActivated: true,
};
