import { Role } from "@/acl/roles";
import { BCJ_PRODUCTS_DETAILS } from "@/assets/constants/products/bcj";

export default {
  orgName: "BCJ",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/bcj.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 100px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/bcj.jpg"),
  },
  products: BCJ_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["fr"],
  colorScheme: [
    "#FA000D",
    "#B4B4B4",
    "#000000",
    // Same colors with less opacity
    "#ED2E38",
    "#C8C8C8",
    "#323232",
  ],
};
