import { Role } from "@/acl/roles";
import { ALQUANT_PRODUCTS_DETAILS } from "@/assets/constants/products/alquant";
import { i18n } from "@/main.js";

export default {
  orgName: "Alquant",
  showHomePageHeader: true,
  homePageLogoPath: require("@/assets/images/logo/alquant.svg"),
  logoPath: require("@/assets/images/logo/alquant-colored.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google", "Microsoft"],
  background: {
    path: require("@/assets/images/background/alquant.svg"),
  },
  slogan: true,
  products: ALQUANT_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    faq: {
      // A function here to be able to use i18n. Otherwise, this
      // file is called and the data initialized before i18n is
      // ready.
      faqs: () => [
        {
          id: 1,
          question: i18n.t("wlps.alquant.faq.whatAlquant.question"),
          ans: i18n.t("wlps.alquant.faq.whatAlquant.answer"),
        },
        {
          id: 2,
          question: i18n.t("wlps.alquant.faq.whatGoal.question"),
          ans: i18n.t("wlps.alquant.faq.whatGoal.answer"),
        },
        {
          id: 3,
          question: i18n.t("wlps.alquant.faq.howToInvest.question"),
          ans: i18n.t("wlps.alquant.faq.howToInvest.answer"),
        },
        {
          id: 4,
          question: i18n.t("wlps.alquant.faq.whatDataSources.question"),
          ans: i18n.t("wlps.alquant.faq.whatDataSources.answer"),
        },
        {
          id: 999,
          question: i18n.t("wlps.alquant.faq.moreQuestions.question"),
          ans: i18n.t("wlps.alquant.faq.moreQuestions.answer"),
        },
      ],
    },
    contact: true,
  },
  possibleRoles: [
    Role.superAdmin,
    Role.admin,
    Role.employee,
    Role.investor,
    Role.alvolaCHF,
    Role.lifeSciencesToolsAndServices,
  ],
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
